import Menu from "./Menu/Menu";
import Navbar from "./Navbar/Navbar";
import {FC} from "react";
import CookieTecnicalBar from "../../../shared/components/CookieBars/CookieTecnicalBar/CookieTecnicalBar";

const UI: FC = () => {


    return (
        <>
            <Menu/>
            <Navbar/>
            <CookieTecnicalBar/>
        </>
    )
        ;
}

export default UI;

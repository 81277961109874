import {lazy} from "react";
import {SpecialCategoryEnum} from "./core/enums/SpecialCategory.enum";
import {titlePrefix} from "./core/constants";
import NotFound from "./pages/NotFound/NotFound";

const Home = lazy(() => import("./pages/Home"));
const ArticleViewer = lazy(() => import("./pages/ContentViewer/ArticleViewer"));
const EventsViewer = lazy(() => import("./pages/ContentViewer/EventsViewer"));
const SCNViewer = lazy(() => import("./pages/ContentViewer/SCNViewer"));
const CategoryViewerArticle = lazy(() => import("./pages/CategoryViewer/CategoryViewerArticle"));
const CategoryViewerEvents = lazy(() => import("./pages/CategoryViewer/CategoryViewerEvents"));
const CategoryViewerPlace = lazy(() => import("./pages/CategoryViewer/CategoryViewerPlace"));
const CategoryViewerAuthor = lazy(() => import("./pages/CategoryViewer/CategoryViewerAuthor"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const Bio = lazy(() => import("./pages/Bio/Bio"));
const CategoryViewerScn = lazy(() => import("./pages/CategoryViewer/CategoryViewerScn"));
const Privacy = lazy(() => import("./pages/Privacy/Privacy"));
const Cookie = lazy(() => import("./pages/Cookie/Cookie"));
const Search = lazy(() => import("./pages/Search/Search"));
const CategoryViewerMixed = lazy(() => import("./pages/CategoryViewer/CategoryViewerMixed"));

const Routes = [
    {
        path: "/",
        component: <Home/>,
        title: `${titlePrefix}il sito per rimanere sempre aggiornato sul mondo dei viaggi`,
        pageName: 'news_feed'
    },
    {path: "/articles/:slug", component: <ArticleViewer/>, title: `${titlePrefix}`, pageName: 'viewer'},
    {path: "/events/:slug", component: <EventsViewer/>, title: `${titlePrefix}`, pageName: 'viewer'},
    {path: "/scopriloconnoi/:slug", component: <SCNViewer/>, title: `${titlePrefix}`, pageName: 'viewer'},
    {
        path: '/tag/articles/:tag',
        component: <CategoryViewerArticle/>,
        title: `${titlePrefix}Categoria Articoli - `,
        pageName: 'categories'
    },
    {
        path: '/tag/events/:tag',
        component: <CategoryViewerEvents/>,
        title: `${titlePrefix}Categoria Eventi - `,
        pageName: 'categories'
    },
    {
        path: '/tag/place/:tag',
        component: <CategoryViewerPlace/>,
        title: `${titlePrefix}Luogo - `,
        pageName: 'categories'
    },
    {
        path: '/authors/articles/:tag',
        component: <CategoryViewerAuthor/>,
        title: `${titlePrefix}Autore - `,
        pageName: 'categories'
    },
    {
        path: '/articles',
        component: <CategoryViewerArticle tag={SpecialCategoryEnum.LATESTSAARTICLES}/>,
        title: `${titlePrefix}Ultimi Articoli`,
        pageName: 'categories'
    },
    {
        path: '/events',
        component: <CategoryViewerEvents tag={SpecialCategoryEnum.EVENTS}/>,
        title: `${titlePrefix}Eventi`,
        pageName: 'categories'
    },
    {
        path: '/latests',
        component: <CategoryViewerMixed/>,
        title: `${titlePrefix}Ultimi Articoli`,
        pageName: 'categories'
    },
    {path: '/aboutus', component: <AboutUs/>, title: `${titlePrefix}Chi siamo?`, pageName: 'about_us'},
    {path: '/authors/bio/:slug', component: <Bio/>, title: `${titlePrefix}`, pageName: 'author_bio'},
    {
        path: '/scopriloconnoi',
        component: <CategoryViewerScn/>,
        title: `${titlePrefix}Scoprilo Con Noi`,
        pageName: 'scoprilo_con_noi_feed'
    },
    {path: '/privacy', component: <Privacy/>, title: `${titlePrefix}Privacy Policy`, pageName: 'privacy_policy'},
    {path: '/cookies', component: <Cookie/>, title: `${titlePrefix}Cookies Policy`, pageName: 'cookie_policy'},
    {path: '/search', component: <Search/>, title: `${titlePrefix}Cerca Contenuti`, pageName: 'search'},
    /*{path: '/game', component: <Game/>, title: `${titlePrefix}Cerca Contenuti`},
    {path: '/game/result', component: <GameResult/>, title: `${titlePrefix}Cerca Contenuti`}*/

    //toredirect
    {path: "/404", component: <NotFound/>, title: `${titlePrefix}`, pageName: '404'},
];
export default Routes

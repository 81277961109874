import React, {FC, useState} from "react";
import {IonButton, IonCol, IonRouterLink, IonRow} from "@ionic/react";
import './CookieTecnicalBar.scss'
import {CookieManager} from "../../../utils/CookieManager";

function closeAndAcceptAllPreferences() {
    CookieManager.getInstance().cookieSettedFlag();
    CookieManager.getInstance().setAllFlag(true);
}

function closeAndRejectAllPreferences() {
    CookieManager.getInstance().cookieSettedFlag();
    CookieManager.getInstance().setAllFlag(false);
}


const CookieTecnicalBar: FC = () => {
    const [showCookie, setShowCookie] = useState(true)

    return (
        <>
            {(showCookie) &&
                <>
                    <div className="position-fixed bar-container d-flex justify-content-center w-100">
                        <div className="background-color-theme-inverted w-100 bar">
                            <IonRow>
                                <IonCol sizeSm="10" size="12">
                                    <p className="p-3">
                            <span className="text-color-inverted"> Scoprilo utilizza dei cookie tecnici  per assicurarti la migliore esperienza, continuando la navigazione
                                acconsenti al loro utilizzo.  <IonRouterLink routerLink="cookies"
                                                                             className="text-decoration-underline">Maggiori info...</IonRouterLink> </span>
                                    </p>

                                </IonCol>
                                <IonCol sizeSm="2" size="12"
                                        className="d-flex justify-content-center flex-column">
                                    <IonButton onClick={() => {
                                        setShowCookie(false)
                                    }} aria-label="Accetta Cookie">Ok</IonButton>
                                </IonCol>
                            </IonRow>
                        </div>
                    </div>
                </>
            }</>
    );
}

export default CookieTecnicalBar

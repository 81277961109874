import {ajax} from "rxjs/ajax";

export class HttpClient {
    private static apiToken = 'uIwrgWrpSgvLi9(GYvgJUIf?k%K$jgnjg{A**DrwgwHUF/(JNF(Ob4qe4ug80hb';

    static get(url: string){
        return ajax.get(url, {apikey: this.apiToken})
    }
    static post(url: string, body: {[p:string]: any}){
        return ajax.post(url, body, {apikey: this.apiToken})
    }

    static put(url: string, body: {[p:string]: any}){
        return ajax.put(url, body, {apikey: this.apiToken})
    }

    static delete(url: string, body: {[p:string]: any}){
        return ajax.delete(url, {apikey: this.apiToken})
    }
}
import {Drivers, Storage} from '@ionic/storage';
import {CookieKeysEnum} from "../../core/enums/CookieKeys.enum";

export class CookieManager {
  private static singleton: CookieManager;
  storage: Storage;
  constructor() {
    this.storage = new Storage({
      driverOrder: [Drivers.LocalStorage]
    });
    this.storage.create().then();
  }


  private getMonthsDiff(date: string): number{
    const currentDate = new Date();
    const dateSent = new Date(date);
    return Math.floor((
      Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        currentDate.getUTCDate(),
        currentDate.getUTCHours(),
        currentDate.getUTCMinutes(),
        currentDate.getUTCSeconds()) -
      Date.UTC(
        dateSent.getFullYear(),
        dateSent.getMonth(),
        dateSent.getDate(),
        dateSent.getUTCHours(),
        dateSent.getUTCMinutes(),
        dateSent.getUTCSeconds()) ) / (1000 * 60 * 60 * 24 * 30));
  }

  public async showOrHideCookieBar(setCookieCallback: (value: boolean) => void): Promise<void> {
    await this.storage.get(CookieKeysEnum.COOKIE_AGREE_STATUS).then(value => {
        this.storage.get(CookieKeysEnum.COOKIE_SETUP_DATE).then(value2 => {
          let currentCookieStatus = value;
          if (!currentCookieStatus || this.getMonthsDiff(value2) >= 6 || (currentCookieStatus && !value2)) {
            this.initCookieFields();
            currentCookieStatus = false;
          }
          setCookieCallback(!currentCookieStatus);
        });
      });
  }
  public async checkIfCookieIsAgreed(trueValueCallback: () => void, falseValueCallback: () => void) {
    await this.storage.get(CookieKeysEnum.COOKIE_AGREE_STATUS).then(value => {
      if (value){
        trueValueCallback();
      } else {
        falseValueCallback();
      }
    });
  }

  public async getCookieStatus(key: CookieKeysEnum, status: (value: boolean) => void) {
    await this.storage.get(key).then(value => {
      status(value);
    });
  }
  public async getCookiesStatus(key: CookieKeysEnum[], status: (value: boolean[]) => void) {
    const cookieStatuses: boolean[] = [];
      for (const k of key) {
        await this.storage.get(k).then(value => {
          cookieStatuses.push(value);
        });
      }
    status(cookieStatuses);
  }

  /*public async getCookiesStatusAndSave() {
    Object.keys(CookieKeysEnum).filter(async k => {
      await this.storage.ready().then(async () => await this.storage.get(CookieKeysEnum[k]).then(value => {
        this.cookieStatus[CookieKeysEnum[k]].next(value);
      }));
    });
  }*/

  public initCookieFields(): void {
    this.storage.set(CookieKeysEnum.COOKIE_AGREE_STATUS, false);
    this.storage.set(CookieKeysEnum.ANALYTICS, false);
    this.storage.set(CookieKeysEnum.HOTJAR, false);
    this.storage.remove(CookieKeysEnum.COOKIE_SETUP_DATE);
  }

  cookieSettedFlag() {
    this.storage.set(CookieKeysEnum.COOKIE_AGREE_STATUS, true);
    const date = new Date();
    this.storage.set(CookieKeysEnum.COOKIE_SETUP_DATE, Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
  }

  setAllFlag(value: boolean) {

    this.storage.set(CookieKeysEnum.ANALYTICS, value);
     /*HOTJAR DISABLED
    this.storage.set(CookieKeysEnum.HOTJAR, value);
    */
  }

  setFlag(key: CookieKeysEnum, value: any) {
    this.storage.set(key, value);
  }

  static getInstance(){
    if(!this.singleton){
      this.singleton = new CookieManager();
    }
    return this.singleton;
  }
}



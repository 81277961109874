import './Navbar.scss'
import {
    IonButton,
    IonButtons,
    IonCol,
    IonHeader,
    IonIcon,
    IonImg,
    IonMenuButton,
    IonRouterLink,
    IonRow,
    IonText,
    IonToolbar
} from "@ionic/react";
import {MenuLinks} from "../../../menu-links";
import {Breakpoints, useScreenBreakpoints} from "../../../hooks/useScreenBreakpoints";
import React from "react";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";

function checkActualLink(url: string, currentPath: string) {
    if (currentPath.includes(url)) {
        if (url == '/' && currentPath == '/') {
            return 'selected'
        } else if ((url != '/' && currentPath == '/') || (url == '/' && currentPath != '/')) {
            return 'not-selected'
        } else {
            return 'selected'
        }
    } else {
        return 'not-selected'
    }
}

const Navbar: React.FC = () => {
    const width = useScreenBreakpoints();
    const history = useHistory();
    const location = useLocation();

    return (
        <IonHeader>
            <IonToolbar>
                <nav className="navcolor">

                    <IonRow className="px-md-3 py-2">
                        {(width < Breakpoints.MD) && <IonCol>
                            <IonButtons aria-label="Menù">
                                <IonMenuButton className="text-color"/>
                            </IonButtons>
                        </IonCol>}
                        <IonCol
                            className="d-flex justify-content-center justify-content-md-start pe-5 pe-md-0">
                            <IonRouterLink routerLink="/" routerDirection="none" className="ms-5 ms-md-0">
                                <div className="d-flex flex-row">
                                    <IonImg alt="Scoprilo.net" src="assets/nav/logo-small.svg"
                                            className="logo me-3 me-md-0"></IonImg>
                                    {(width > Breakpoints.SM) &&
                                        <h1 className="m-0 site-name text-color ms-4">Scoprilo.net</h1>}
                                </div>
                            </IonRouterLink>
                        </IonCol>
                        {(width > Breakpoints.SM) &&
                            <IonCol className="d-flex align-items-center justify-content-center">
                                <IonRow className={`links`}>
                                    {MenuLinks.appPages.map((appPage, index) => {
                                        return (
                                            <IonCol className="me-3" key={index}>
                                                {/* eslint-disable-next-line no-restricted-globals */}
                                                <IonRouterLink routerLink={appPage.url} routerDirection="none"
                                                               className={`link-text text-uppercase ${checkActualLink(appPage.url, location.pathname)}`}>
                                                    <IonText>{appPage.title}</IonText>
                                                </IonRouterLink>
                                            </IonCol>
                                        );
                                    })}
                                </IonRow>
                            </IonCol>}
                        <div className="col col-md-1 col-lg d-flex flex-row justify-content-end align-items-center">
                            {/*<IonToggle checked={IsDarkMode()} onIonChange={(evt)=>{
                            ThemeToggle(evt.detail.checked);
                        }} className="themeToggle"></IonToggle>*/}
                            <IonButton className="text-color pointer-cursor" onClick={() => {
                                history.push('/search')
                            }} fill={(location.pathname === '/search') ? 'solid' : 'clear'} aria-label="Cerca">
                                <IonIcon slot="icon-only" src="assets/nav/search.svg" className="logo searchbtn"/>
                            </IonButton>
                        </div>
                    </IonRow>
                </nav>
            </IonToolbar>
        </IonHeader>
    );
};

export default Navbar;

export class MenuLinks {
    static appPages: AppPage[] = [
        {
            title: 'Home',
            url: '/'
        },
        {
            title: 'Articoli',
            url: '/articles'
        },
        {
            title: 'Eventi',
            url: '/events'
        },
        {
            title: 'Scoprilo Con Noi',
            url: '/scopriloconnoi'
        },
        {
            title: 'Chi Siamo',
            url: '/aboutus'
        }
    ];
}

interface AppPage {
    url: string;
    title: string;
}

import {IonContent, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle,} from '@ionic/react';

import {useLocation} from 'react-router-dom';
import './Menu.scss';
import {MenuLinks} from "../../../menu-links";
import {Breakpoints, useScreenBreakpoints} from "../../../hooks/useScreenBreakpoints";
import React from "react";

function checkActualLink(url: string, currentPath: string) {
    if (currentPath.includes(url)) {
        if (url == '/' && currentPath == '/') {
            return 'selected'
        } else if ((url != '/' && currentPath == '/') || (url == '/' && currentPath != '/')) {
            return 'not-selected'
        } else {
            return 'selected'
        }
    } else {
        return 'not-selected'
    }
}

const Menu: React.FC = () => {
    const location = useLocation();
    const screenDim = useScreenBreakpoints();
    return (
        <IonMenu contentId="main" type="overlay" disabled={screenDim > Breakpoints.SM} style={{'ZIndex': 1000}}>
            <IonContent>
                <IonList>
                    {MenuLinks.appPages.map((appPage, i) => {
                        return (
                            <IonMenuToggle autoHide={false} key={i}>
                                <IonItem
                                    className={`link-text text-uppercase  ${checkActualLink(appPage.url, location.pathname)}`}
                                    routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default React.memo(Menu);

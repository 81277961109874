import {IonApp, IonPage, IonRouterOutlet, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Redirect, Route} from 'react-router-dom';
import ReactGA from 'react-ga4';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import Routes from "./Routes";
import UI from "./core/components/UI/UI";
import './App.scss'
import React, {useEffect} from "react";
import {AnimatePresence} from "framer-motion";
import LazyFallback from "./core/components/LazyFallback";

setupIonicReact();

function checkCookies() {
    //disabilitato in quanto GA diverta cookie tecnico
    /*CookieManager.getInstance().checkIfCookieIsAgreed(() => {
        CookieManager.getInstance().getCookieStatus(CookieKeysEnum.ANALYTICS, (status) => {
            if (status) {
                ReactGA.initialize(process.env.REACT_APP_GA_ID!);
                console.log('analytics')
            }
        })
    }, () => {
    })*/

    //riga per ga cookie tecnico
    ReactGA.initialize(process.env.REACT_APP_GA_ID!);
}

const App: React.FC = () => {
    useEffect(() => {
        checkCookies()
    }, []);

    return (
        <IonApp>
            <IonReactRouter>
                <IonPage>
                    <UI/>
                    <AnimatePresence>
                        <React.Suspense fallback={<LazyFallback/>}>
                            <IonRouterOutlet className="router-margin" id="main">
                                {Routes.map((route, i) => {
                                    return (<Route path={route.path} key={i} exact={true}>
                                        {route.component}
                                    </Route>)
                                })}

                                {/*toredirect*/}
                                <Route exact path={'/news/:slug'} render={(props) => {
                                    return <Redirect to={`/articles/${props.match.params.slug}`}/>
                                }}/>
                                <Route exact path={'/tag/news/:tag'} render={({match, staticContext}) => {
                                    if (!!staticContext) staticContext.statusCode = 500;
                                    return <Redirect to={`/tag/articles/${match.params.tag}`}/>
                                }}/>
                                <Route exact path={'/news'} render={({staticContext}) => {
                                    if (!!staticContext) staticContext.statusCode = 500;
                                    return <Redirect to={'/articles'}/>
                                }}/>

                                <Route render={() => {
                                    return <Redirect to={'/404'}/>
                                }}></Route>
                            </IonRouterOutlet>
                        </React.Suspense>
                    </AnimatePresence>
                </IonPage>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;

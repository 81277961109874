import React, {useRef} from "react";
import {IonCol, IonFooter, IonIcon, IonModal, IonRouterLink, IonRow,} from "@ionic/react";
import './Footer.scss';
import {logoFacebook, logoInstagram} from "ionicons/icons";
import CookieModal from "../../../../shared/components/CookieModal/CookieModal";


const Footer: React.FC = () => {
    const modalCookie = useRef<HTMLIonModalElement>(null);
    return (
        <>
            <IonFooter className="text-color">
                <IonRow className="pb-3">
                    <IonCol sizeMd="7" size="12" className="ps-2">
                        <p>
                            Scoprilo.net ti offre informazioni sul mondo dei viaggi e del turismo in Italia e nel mondo,
                            per
                            aiutarti a scoprire di più sulle mete e gli eventi culturali che ti interessano.
                        </p>
                    </IonCol>
                    <IonCol className="d-flex justify-content-center flex-column" size="12" sizeMd="5">
                        <IonRow className="d-flex justify-content-center">
                            <p className="follow-us fw-bold m-0">Seguici su:</p>
                        </IonRow>
                        <IonRow className="d-flex justify-content-center">
                            <IonCol size="2" className="d-flex justify-content-center">
                                <IonIcon icon={logoFacebook}
                                         onClick={() => window.location.href = 'https://www.facebook.com/Scoprilonet-102363448437601/'}
                                         className="cursor-pointer social-icon"></IonIcon>
                            </IonCol>
                            <IonCol size="2" className="d-flex justify-content-center">
                                <IonIcon icon={logoInstagram}
                                         onClick={() => window.location.href = "https://www.instagram.com/scoprilo_net"}
                                         className="cursor-pointer social-icon"></IonIcon>
                            </IonCol>
                        </IonRow>
                        <IonRow className="d-flex justify-content-center">
                            <IonRouterLink routerLink="privacy" className="text-color me-2">Privacy
                                Policy</IonRouterLink>
                            <IonRouterLink routerLink="cookies" className="text-color me-2">Cookie
                                Policy</IonRouterLink>
                            <IonRouterLink onClick={() => modalCookie.current?.present()}
                                           className="text-color cursor-pointer">Modifica Cookie</IonRouterLink>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </IonFooter>
            <IonModal ref={modalCookie}>
                <CookieModal onDismiss={() => modalCookie.current?.dismiss()}/>
            </IonModal>
        </>
    );
};
export default React.memo(Footer);

import React, {FC, useEffect, useRef, useState} from "react";
import {
    IonButton,
    IonCol,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar,
    useIonAlert
} from "@ionic/react";
import {helpSharp} from "ionicons/icons";
import {CookieManager} from "../../utils/CookieManager";
import {CookieKeysEnum} from "../../../core/enums/CookieKeys.enum";
import './CookieModal.scss';

function confirmModalPrivacy(hotjar: boolean, GA: boolean) {
    CookieManager.getInstance().cookieSettedFlag();
    CookieManager.getInstance().setFlag(CookieKeysEnum.ANALYTICS, GA);
    CookieManager.getInstance().setFlag(CookieKeysEnum.HOTJAR, hotjar);
}

interface Props {
    onDismiss: (accepted: boolean) => void;
}


const CookieModal: FC<Props> = (props) => {
    const [startcheckedAnalytics, setStartCheckedAnalytics] = useState(false);
    const [startcheckedHotjar, setStartCheckedHotjar] = useState(false);
    const [present] = useIonAlert();
    const analyticsToggle = useRef<HTMLIonToggleElement>(null)
    const hotjarToggle = useRef<HTMLIonToggleElement>(null)

    useEffect(() => {
        CookieManager.getInstance().getCookiesStatus([CookieKeysEnum.ANALYTICS, CookieKeysEnum.HOTJAR], (value) => {
            setStartCheckedAnalytics(value[0]);
            setStartCheckedHotjar(value[1]);
        })
    });

    function AlertView(title: string, text: string) {
        present({
            header: title,
            message: text,
            buttons: ['Ho Capito!']
        })
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Seleziona cookie</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonCol>
                <IonRow class="mt-5">
                    <IonText class="px-4 text-color">
                        I cookie qui elencati non raccolgono alcun dato personale, sono utilizzati in maniera anonima
                        per capire cosa
                        piace agli utenti di Scoprilo, così da poter migliorare il sito
                    </IonText>
                </IonRow>
                <IonRow>
                    <IonCol size="12">
                        <IonList>
                            <IonItem>
                                <IonToggle checked={startcheckedAnalytics} ref={analyticsToggle}
                                           className="toggles"></IonToggle>
                                <IonLabel>Google Analytics</IonLabel>
                                <IonIcon icon={helpSharp}
                                         onClick={() => AlertView('Google Analitics', 'Google Analytics ci consente di sapere quali sono gli articoli più letti e le sezioni del sito più navigate, così da poter migliorare i nostri contenuti')}
                                         className="cursor-pointer"></IonIcon>
                            </IonItem>
                            {/*HOTJAR DISABLED
                            <IonItem>
                                <IonToggle checked={startcheckedHotjar} ref={hotjarToggle} className="toggles"></IonToggle>
                                <IonLabel>Hotjar</IonLabel>
                                <IonIcon icon={helpSharp}
                                         onClick={() => AlertView('Hotjar', 'Hotjar è una piattaforma che ci permette di vedere i punti più cliccati di Scoprilo, cosi possiamo capire cosa vi incuriosisce di più e di conseguenza investire tempo per migliorarlo')}
                                         className="cursor-pointer"></IonIcon>
                            </IonItem>*/}
                        </IonList>
                    </IonCol>
                </IonRow>
            </IonCol>
            <div className="d-flex justify-content-center">
                <IonButton onClick={() => {
                    //HOTJAR DISABLED confirmModalPrivacy((!!hotjarToggle.current?.checked) ? hotjarToggle.current?.checked: false, (!!analyticsToggle.current?.checked) ? analyticsToggle.current?.checked: false)
                    confirmModalPrivacy(false, (!!analyticsToggle.current?.checked) ? analyticsToggle.current?.checked : false)
                    props.onDismiss(true)
                }}
                           class="col-5" slot="fixed" aria-label="Conferma">
                    Conferma
                </IonButton>
                <IonButton aria-label="Annulla"
                           onClick={() => {
                               props.onDismiss(false)
                           }}
                           slot="fixed"
                           className="col-5">
                    Annulla
                </IonButton>
            </div>
        </>
    );
}

export default CookieModal;

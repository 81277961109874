import {useEffect, useState} from "react";

export enum Breakpoints {
    XS,
    SM,
    MD,
    LG,
    XL,
    XXL
}

export function useScreenBreakpoints() {
    const [dimension, setDimension] = useState(Breakpoints.XXL);

    useEffect(() => {
        handleWidth();
        function handleWidth() {
            let width = window.innerWidth;
            if (width < 576) {
                setDimension(Breakpoints.XS);
            } else if (width >= 576 && width < 768) {
                setDimension(Breakpoints.SM);
            } else if (width >= 768 && width < 992) {
                setDimension(Breakpoints.MD);
            } else if (width >= 992 && width < 1200) {
                setDimension(Breakpoints.LG);
            } else if (width >= 1200 && width < 1400) {
                setDimension(Breakpoints.XL);
            } else if (width >= 1400) {
                setDimension(Breakpoints.XXL);
            }
        }
        window.addEventListener('resize', handleWidth)
    });
    return dimension;
}

import Wrapper from "../../core/components/UI/Wrapper/Wrapper";
import {IonButton, IonImg} from "@ionic/react";
import {useHistory} from "react-router";
import './NotFound.scss'
import {Helmet} from "react-helmet";
import {titlePrefix} from "../../core/constants";

const NotFound = () => {
    const errorCode = 404;
    const errorText = 'Pagina Non Scoperta...';
    const history = useHistory();
    return (
        <>
            <Helmet>
                <title>{`${titlePrefix}Ops Pagina Non Trovata`}</title>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <Wrapper>
                <div
                    className="w-100 h-100 d-flex flex-column justify-content-center align-items-center errorcontainer">
                    <IonImg className="img" src="assets/body/404.svg"/>
                    <h3 className="mt-3">{errorCode} {errorText}</h3>
                    <IonButton className="text-color" onClick={() => history.push('/')} aria-label="Torna alla home">Torna
                        Alla Home Page</IonButton>
                </div>
            </Wrapper>
        </>
    );
}

export default NotFound;

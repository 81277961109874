import Routes from "../../Routes";

export class CurrentRoute {
    static GetCurrentRouteDetails(){

       return Routes.find((val)=>{
            let splitVal = val.path.substring(1).split('?')[0].split('/');
            let splitLocation = document.location.pathname.substring(1).split('?')[0].split('/');
            if(splitVal.length == splitLocation.length){
                return splitVal.every((v, i) =>{
                    if(v.includes(':')){
                        return true;
                    } else {
                        return v == splitLocation[i];
                    }
                })
            } else {
                return false;
            }
        });
    }
}
